@use '../includes' as *;

.sponsorship_unit {
  // We use display: none as the default so that we
  // completely hide the unit when it's below certain breakpoints.
  display: none;
  // We use visibility: hidden above those breakpoints, but
  // before the unit is rendered, so that we prevent layout shift.
  visibility: hidden;
  transition-property: opacity;
  transition-duration: var(--duration-half);
  transition-timing-function: var(--ease-in-out);
  opacity: 0;

  // this class is toggled if the unit is above the desired breakpoint
  &.sponsorship_unit__in_flow {
    display: block;
  }

  &.sponsorship_unit__rendered {
    visibility: visible;
    opacity: 1;
  }

  &.sponsorship_unit__full_width {
    width: max-content;
    margin: 0 auto;
    padding-block-end: 20px;
    padding-inline: var(--spacing-inline);
  }

  // if google collapses their ad div, they add an inline style of display: none
  // if that happens, we hide the whole unit
  &:has(> div[data-google-query-id][style='display: none;']),
  &:has(div[id*='billboard']:empty) {
    display: none;
  }
}

.sponsorship_explanation {
  display: flex;
  justify-content: space-between;
  padding-block-start: $g8;
}

.sponsorship_mobile_explanation {
  padding-block-end: $g4;
}

.sponsorship_explanation__text {
  @include body-copy-2;
}

.sponsorship_explanation__link {
  @include h6;
}
